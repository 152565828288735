import BasicLayout from "../layouts/basic";

const ErrorPage = () => {
  return (
    <BasicLayout>
      <title>Nope</title>
      <div className="relative h-[95vh] overflow-hidden">
        <div className="z-10 flex flex-col justify-center items-center h-full">
          <p className="font-medium">You broke it</p>
        </div>
      </div>
    </BasicLayout>
  );
};

export default ErrorPage;
