import { PropsWithChildren } from "react";
import style from "./circleGrid.module.css";

const CircleGrid: React.FC<PropsWithChildren> = ({ children }) => {
  return (
    <div className={style.galleryContainer}>
      <div className={style.gallery}>{children}</div>
    </div>
  );
};

export default CircleGrid;
