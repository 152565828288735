import { useState, useMemo, useEffect } from "react";
import { m } from "framer-motion";
import cx from "classnames";

import CurlyArrow from "../../animations/curly-arrow";
import useUpper from "../../hooks/useUpper";
import style from "./Hero.module.css";

const Hero = () => {
  const screen = useUpper();
  const [showAnimation, setShowAnimation] = useState<boolean>(true);
  const highlightTransition = {
    delay: 1.5,
    duration: 0.6,
    ease: "easeOut",
    opacity: { delay: 1 },
  };
  const highlightWidth = useMemo(() => {
    switch (screen) {
      case "xxs":
        return "230px";
      case "xs":
        return "300px";
      default:
        return "370px";
    }
  }, [screen]);

  const headerVariants = {
    start: { opacity: 0, scale: 0 },
    end: { opacity: 1, scale: 1, transition: { delay: 1, duration: 0.5 } },
  };

  const outArrowAnimationVariants = {
    xxs: { rotate: 10, scale: 1, x: -10, y: 35 },
    xs: { rotate: 30, scale: 1, x: -20, y: 5 },
    sm: { rotate: 30, scale: 1.5, x: -40, y: 45 },
    md: { rotate: 30, scale: 1.5, x: -50, y: 45 },
    lg: { rotate: 30, scale: 2, x: -60, y: 60 },
    xl: { rotate: 30, scale: 2, x: -55, y: 55 },
    xxl: { rotate: 30, scale: 2.3, x: -75, y: 70 },
    xxxl: { rotate: 30, scale: 3, x: -75, y: 70 },
    xxxxl: { rotate: 30, scale: 3.4, x: -84, y: 80 },
  };

  const inArrowAnimationVariants = {
    xxs: { rotate: 45, scale: 1, x: -20, y: 200 },
    xs: { rotate: 45, scale: 1, x: -180, y: 200 },
    sm: { rotate: 25, scale: 1.5, x: -60, y: 50 },
    md: { rotate: 30, scale: 1.5, x: -60, y: 65 },
    lg: { rotate: 60, scale: 2, x: -85, y: 120 },
    xl: { rotate: 50, scale: 2, x: -115, y: 100 },
    xxl: { rotate: 68, scale: 2.3, x: -40, y: 100 },
    xxxl: { rotate: 45, scale: 3, x: -90, y: 60 },
    xxxxl: { rotate: 60, scale: 3.3, x: -90, y: 50 },
  };

  useEffect(() => {
    setTimeout(() => setShowAnimation(false), 11000);
  }, []);

  return (
    <div className="py-24 relative">
      <div
        className={cx("mb-4 mx-auto", style.typewriter)}
        style={{ width: "200px" }}
      >
        <h3 className="uppercase text-teal-600 font-medium font-title text-sm tracking-widest relative z-10">
          Your New Developer
        </h3>
      </div>
      {showAnimation && (
        <div className="absolute top-0 -left-80">
          <CurlyArrow
            variants={inArrowAnimationVariants}
            transition={{
              delay: 2,
              repeat: 1,
              repeatType: "reverse",
              repeatDelay: 1,
            }}
          />
        </div>
      )}
      <div
        className="relative mx-auto"
        style={{ width: ["xs", "xxs"].includes(screen) ? "100%" : "650px" }}
      >
        {showAnimation && (
          <div className="absolute bottom-6 -right-52">
            <CurlyArrow
              variants={outArrowAnimationVariants}
              transition={{
                delay: 1.5,
                repeat: 1,
                repeatType: "reverse",
                repeatDelay: 2.2,
              }}
            />
          </div>
        )}
        <m.h1
          initial="start"
          animate="end"
          variants={headerVariants}
          className="font-bold text-4xl xs:text-5xl sm:text-6xl relative z-10"
        >
          The developer
        </m.h1>
        <div className="grid grid-cols-2 gap-x-2 mx-auto mt-2 -ml-40 xs:-ml-48 sm:-ml-64 relative">
          <m.div
            initial={{ opacity: 0, x: 0 }}
            animate={{ opacity: 1, x: highlightWidth }}
            transition={highlightTransition}
            className="row-start-1 col-start-2 w-1 h-11 xs:h-14 sm:h-16 -mx-1 bg-green-500 rounded-m mb-4"
          >
            <m.div
              className="h-6 relative bottom-5 bg-green-500 rounded-md rounded-bl-none font-bold px-2 text-white text-left"
              initial={{ width: 0, opacity: 0 }}
              animate={{ width: "42px", opacity: 1 }}
              transition={{
                delay: 2,
                duration: 0.3,
                opacity: { duration: 0.1 },
              }}
            >
              Me!
            </m.div>
          </m.div>
          <m.div
            initial={{ width: 0 }}
            animate={{ width: highlightWidth }}
            transition={highlightTransition}
            className="row-start-1 col-start-2 bg-green-500 opacity-30 h-11 xs:h-14 sm:h-16 -mx-1 rounded-xl rounded-r-none"
          />
          <m.h1
            initial="start"
            animate="end"
            variants={headerVariants}
            className="font-bold text-4xl xs:text-5xl sm:text-6xl mb-4 row-start-1 col-span-1 row-span-1 justify-self-end"
          >
            you
          </m.h1>
          <m.h1
            initial="start"
            animate="end"
            variants={headerVariants}
            className="row-start-1 col-start-2 font-bold text-4xl xs:text-5xl sm:text-6xl mb-4 ml-1 row-span-1 col-span-2 justify-self-start z-10"
          >
            need to meet
          </m.h1>
        </div>
      </div>
      <p className="text-lg sm:text-xl leading-tight tracking-tight font-body w-64 sm:w-96 mx-auto relative z-10">
        Bringing you state-of-the-art, user-centric web apps and software tools.
      </p>
    </div>
  );
};

export default Hero;
