const Connect = ({ fill }: { fill: string }) => {
	return (
		<svg version="1.0" xmlns="http://www.w3.org/2000/svg"
 viewBox="0 0 512.000000 512.000000"
 preserveAspectRatio="xMidYMid meet">

<g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
fill={fill }stroke="none">
<path d="M967 4756 c-21 -8 -64 -32 -95 -54 -31 -21 -190 -130 -352 -242 -162
-111 -297 -204 -299 -206 -10 -9 -32 -132 -42 -239 -54 -580 268 -1511 805
-2324 195 -295 366 -509 591 -740 288 -296 555 -484 817 -576 l77 -27 386 183
c228 109 403 198 426 218 22 18 49 52 61 75 28 55 36 159 15 208 -30 72 -410
720 -439 749 -64 63 -165 91 -247 69 -24 -6 -142 -65 -261 -131 -120 -65 -223
-119 -230 -119 -31 0 -260 263 -405 466 -174 243 -399 656 -504 923 -44 112
-121 354 -121 381 0 4 101 69 225 144 142 87 235 150 254 174 56 67 76 173 48
252 -15 40 -388 689 -424 736 -55 73 -198 113 -286 80z"/>
<path d="M1906 4008 c25 -68 29 -192 10 -267 -9 -35 -15 -65 -14 -66 2 -1 259
-177 572 -391 313 -214 573 -388 577 -387 6 2 1111 758 1659 1135 24 16 -56
17 -1397 17 l-1422 1 15 -42z"/>
<path d="M4085 3313 c-467 -321 -883 -606 -924 -633 -125 -84 -61 -115 -775
375 -340 233 -624 421 -630 418 -6 -4 -31 -21 -56 -38 -25 -18 -96 -62 -157
-100 -62 -37 -113 -71 -113 -76 0 -5 19 -60 41 -122 107 -290 305 -660 502
-937 74 -103 196 -257 228 -287 l24 -21 165 89 c122 66 183 93 238 105 126 28
265 3 373 -67 87 -56 148 -134 279 -363 l125 -217 700 3 c637 3 703 5 731 20
44 24 91 81 103 125 13 44 15 2281 3 2299 -4 7 -326 -208 -857 -573z"/>
</g>
</svg>
	)
}
export default Connect
