import { useState, useCallback, ChangeEvent } from "react";
import { AnimatePresence, m } from "framer-motion";
import // Navigate,
// useSearchParams,
"react-router-dom";
import cx from "classnames";

import useUpper from "../hooks/useUpper";
import { imgSrc } from "../lib/formatters";
// import { useAuth, Context } from "../context/auth";
import useForm, { HTMLElementEvent } from "../hooks/useForm";
import BasicLayout from "../layouts/basic";
import FormTextInput from "../components/common/form/FormTextInput";
import loginImage from "../img/login/login.png";
import style from "./Login.module.css";

const registrationInfoVariants = {
  hidden: { opacity: 0, height: 0, transition: { duration: 0.5 } },
  shown: { opacity: 1, height: "auto", transition: { duration: 0.5 } },
};

const titleVariants = {
  hidden: { width: ["100%", "0%", "100%"], transition: { duration: 0.6 } },
  shown: { width: ["100%", "0%", "100%"], transition: { duration: 0.6 } },
};

interface FormValues {
  name: string;
  company: string;
  email: string;
  password: string;
}

const validationRules = {
  name: "required",
  company: "optional",
  email: "required",
  password: "required",
};

const Login = () => {
  // const { user, register, login, isLoading, refetchUser } =
  //   useAuth() as Context;
  // const [searchParams] = useSearchParams();
  const isLoading = false;

  const [isRegistering, setIsRegistering] = useState<boolean>(true);
  const [delayedState, setDelayedState] = useState<boolean>(true);
  const [errors, setErrors] = useState<string[]>([]);
  const screen = useUpper();

  const { values, onChange, onSubmit, reset } = useForm(
    {
      name: "",
      company: "",
      email: "",
      password: "",
    },
    () => submitCredentials(values as FormValues)
  );

  const submitCredentials = useCallback(
    async (values: FormValues) => {
      try {
        // if (isLoading) return;
        // const fn = isRegistering ? register : login;
        const fn = (arg: {}) => {};
        const credentials = isRegistering
          ? values
          : { email: values.email, password: values.password };

        const recordedErrors = [];

        // check for missing required credentials
        Object.entries(credentials).forEach(
          ([key, value]: [string, string]) => {
            if (
              validationRules[key as keyof FormValues] === "required" &&
              value === "" &&
              !errors.includes(`missing:${key}`)
            ) {
              setErrors((prev) => [...prev, `missing:${key}`]);
              recordedErrors.push(`missing:${key}`);
            }
          }
        );

        if (recordedErrors.length > 0) return;
        if (errors.length > 0) return;

        await fn(credentials);
        // await refetchUser();
        reset();
      } catch (err: any) {
        setErrors((prev) => [...prev, err.message]);
      }
    },

    [
      // isLoading,
      isRegistering,
      // register, login,
      reset,
      errors,
      // refetchUser
    ]
  );

  const { name, company, email, password } = values as FormValues;

  const registrationInfoState = !isRegistering ? "hidden" : "shown";
  const titleExitState = !isRegistering ? "shown" : "hidden";

  const toggleRegistering = useCallback(() => {
    setIsRegistering((prev) => !prev);
  }, []);

  const toggleDelayedState = useCallback(() => {
    setTimeout(() => {
      setDelayedState((prev) => !prev);
    }, 300);
  }, []);

  // const redirect = Object.fromEntries(searchParams).redirect || "";

  // if (user) {
  //   return <Navigate to={`/${redirect}`} replace={true} />;
  // }

  return (
    <BasicLayout>
      <div
        className="fixed w-full overflow-hidden"
        style={{ height: "94.3vh" }}
      >
        <m.div
          initial={{ opacity: 0, scale: 3 }}
          animate={{ opacity: 1, scale: 1 }}
          transition={{ duration: 2.5 }}
          className="absolute h-full w-full bg-cover bg-center bg-no-repeat"
          style={{
            backgroundImage: `url("${imgSrc(
              "1UPjfVcBCN76VdkCxb39_jyobnx390rJA"
            )}")`,
          }}
        />

        <m.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: 2.4, duration: 1 }}
          style={{
            marginTop: ["lg", "xl", "xxl", "xxxl"].includes(screen)
              ? "220px"
              : "200px",
          }}
          className="lg:h-1/2 w-3/4 sm:w-1/2 xl:w-2/3 max-w-3xl min-w-max min-h-max mx-auto bg-header-pattern rounded-lg flex flex-col md:flex-row shadow-lg shadow-inner"
        >
          <div
            className={cx(
              "hidden lg:block w-3/5 h-full rounded-l-2xl",
              style.radialGradient
            )}
          >
            <img src={loginImage} alt="login" className="h-96 mx-auto mt-4" />
          </div>
          <form
            onSubmit={onSubmit}
            className="relative z-10 w-full lg:w-2/5 mx-auto px-10 px-6 py-8 border-2 border-teal-500 lg:border-l-0 rounded-xl lg:rounded-r-xl lg:rounded-l-none"
          >
            <div className="w-full flex justify-start items-center">
              <AnimatePresence mode="wait">
                <m.h2
                  initial={false}
                  variants={titleVariants}
                  animate={registrationInfoState}
                  exit={titleExitState}
                  className="font-bold font-body text-teal-700 text-xl overflow-hidden whitespace-nowrap text-left"
                >
                  {delayedState ? "Create Account" : "Login"}
                </m.h2>
              </AnimatePresence>
            </div>
            <div className="my-6 flex flex-col gap-y-6">
              <m.div
                className="flex flex-col gap-y-6"
                initial="hidden"
                animate={registrationInfoState}
                variants={registrationInfoVariants}
              >
                <FormTextInput
                  type="text"
                  label="Name"
                  name="name"
                  value={name}
                  onChange={(
                    e: HTMLElementEvent<HTMLInputElement, ChangeEvent>
                  ) => {
                    onChange(e);
                    setErrors([]);
                  }}
                  error={errors.includes("missing:name")}
                />
                <FormTextInput
                  type="text"
                  label="Company"
                  name="company"
                  value={company}
                  onChange={(
                    e: HTMLElementEvent<HTMLInputElement, ChangeEvent>
                  ) => {
                    onChange(e);
                    setErrors([]);
                  }}
                />
              </m.div>
              <FormTextInput
                type="email"
                label="Email"
                name="email"
                value={email}
                onChange={(
                  e: HTMLElementEvent<HTMLInputElement, ChangeEvent>
                ) => {
                  onChange(e);
                  setErrors([]);
                }}
                error={
                  errors.filter(
                    (x) =>
                      x === "missing:email" ||
                      x === "No user exists with that email"
                  ).length > 0
                }
              />
              <FormTextInput
                type="password"
                label="Password"
                name="password"
                value={password}
                onChange={(
                  e: HTMLElementEvent<HTMLInputElement, ChangeEvent>
                ) => {
                  onChange(e);
                  setErrors([]);
                }}
                isPassword
                error={errors.includes("missing:password")}
              />
            </div>
            {errors.filter((x) => x.startsWith("missing")).length > 0 && (
              <div className="h-6 w-full rounded-lg bg-red-100 text-red-500">
                Missing required fields
              </div>
            )}
            {errors.filter(
              (x) =>
                !x.startsWith("missing") &&
                x !== "No user exists with that email"
            ).length > 0 && (
              <div className="h-6 w-full rounded-lg bg-red-100 text-red-500 mt-3">
                {errors
                  .filter((x) => !x.startsWith("missing"))
                  .map((err) => {
                    return <p key={err}>{err}</p>;
                  })}
              </div>
            )}
            {!errors.includes("missing:email") &&
              errors.includes("No user exists with that email") && (
                <div className="h-6 w-full rounded-lg bg-red-100 text-red-500 mt-3">
                  No user exists with that email
                </div>
              )}
            <div className="flex flex-col items-center justify-end gap-x-2 gap-y-4 mt-6">
              <button
                type="submit"
                className="shrink-0 border-2 border-teal-500 rounded-lg px-3 py-1 font-medium w-full bg-teal-500 text-white shadow-lg hover:shadow-md transition ease-in-out"
              >
                {isLoading
                  ? "Loading..."
                  : isRegistering
                  ? "Register"
                  : "Login"}
              </button>
              <button
                type="button"
                onClick={
                  isLoading
                    ? () => {}
                    : () => {
                        toggleRegistering();
                        toggleDelayedState();
                        setErrors([]);
                      }
                }
                className="font-bold"
              >
                or{" "}
                <span className="underline">
                  {isRegistering ? "login" : "create account"}
                </span>
              </button>
            </div>
          </form>
        </m.div>
      </div>
    </BasicLayout>
  );
};

export default Login;
