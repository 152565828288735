import { useState, ChangeEvent } from "react";
import { m } from "framer-motion";
import cx from "classnames";

import { HTMLElementEvent } from "../../../hooks/useForm";
import EyeOpen from "../../../svg/eye";
import EyeClosed from "../../../svg/eye-closed";

interface FormTextInputProps {
  type?: string;
  name: string;
  value: string;
  label: string;
  onChange: (e: HTMLElementEvent<HTMLInputElement, ChangeEvent>) => void;
  className?: string;
  isPassword?: boolean;
  error?: boolean;
}

const FormTextInput: React.FC<FormTextInputProps> = ({
  name,
  value,
  label,
  onChange,
  className,
  type,
  isPassword,
  error,
  ...props
}) => {
  const [isPreviewing, setIsPreviewing] = useState<boolean>(false);
  return (
    <div className={cx("relative w-full", className)}>
      <input
        type={isPassword && isPreviewing ? "text" : type}
        value={value}
        name={name}
        onChange={(e) => onChange(e)}
        {...props}
        className={cx(
          "bg-transparent w-full border-b-2 shadow-xl border-teal-500 focus:outline-none",
          { "border-red-500": error }
        )}
      />
      {isPassword && (
        <button
          className="absolute right-5 h-5 w-5 rounded-full"
          type="button"
          onClick={() => setIsPreviewing((prev) => !prev)}
        >
          {isPreviewing ? <EyeClosed /> : <EyeOpen />}
        </button>
      )}
      <m.label
        initial={false}
        animate={value ? { scale: 0.7, y: -28 } : { scale: 1, y: -8 }}
        transition={{ type: "tween" }}
        className={cx(
          "absolute -left-1 top-2 mt-px px-1 transition-colors duration-200 pointer-events-none origin-left",
          {
            "text-gray-500": !value,
            "text-gray-800": value,
          }
        )}
      >
        {label}
      </m.label>
    </div>
  );
};

export default FormTextInput;
