import { m } from "framer-motion";
import cx from "classnames";

import Carousel from "../common/carousel";
import React from "../../img/logos/react.png";
import nextjs from "../../img/logos/nextjs.png";
import graphql from "../../img/logos/graphql.png";
import node from "../../img/logos/node.png";
import tailwind from "../../img/logos/tailwind.png";
import typescript from "../../img/logos/typescript.png";
import webpack from "../../img/logos/webpack.png";
import docker from "../../img/logos/docker.png";
import apollo from "../../img/logos/apollo.png";
import mysql from "../../img/logos/mysql.png";
import styles from "./technologies.module.css";

const technologies = [
  {
    name: "Tailwind",
    logo: <img src={tailwind} alt="logo" className="h-24 md:h-44" />,
    level: 5,
  },
  {
    name: "React",
    logo: <img src={React} alt="logo" className="h-24 md:h-40" />,
    level: 5,
  },
  {
    name: "NextJS",
    logo: <img src={nextjs} alt="logo" className="h-32 md:h-44" />,
    level: 5,
  },
  {
    name: "GraphQL",
    logo: <img src={graphql} alt="logo" className="h-28 md:h-44" />,
    level: 5,
  },
  {
    name: "Apollo",
    logo: <img src={apollo} alt="logo" className="h-20 md:h-28" />,
    level: 5,
  },
  {
    name: "NodeJS",
    logo: <img src={node} alt="logo" className="h-32 md:h-44" />,
    level: 4,
  },
  {
    name: "TypeScript",
    logo: <img src={typescript} alt="logo" className="h-20 md:h-32" />,
    level: 4,
  },
  {
    name: "Webpack",
    logo: <img src={webpack} alt="logo" className="h-24 md:h-36" />,
    level: 3.5,
  },
  {
    name: "MySQL",
    logo: <img src={mysql} alt="logo" className="h-16 md:h-28" />,
    level: 3.5,
  },
  {
    name: "Docker",
    logo: <img src={docker} alt="logo" className="h-24 md:h-40" />,
    level: 3,
  },
];

const TechSection = () => {
  return (
    <Carousel>
      {Array(10)
        .fill(technologies)
        .flat()
        .map(({ name, logo, level }, i) => (
          <div
            key={`${name} - ${i}`}
            className={cx(
              "group h-56 w-48 md:h-80 md:w-72 rounded-2xl bg-header-pattern backdrop-blur-xl shadow-xl shrink-0 border border-2 border-gray-200 flex flex-col relative",
              styles.card
            )}
          >
            <div className="basis-2/3">
              <div className="flex items-center justify-center h-full">
                {logo}
              </div>
            </div>
            <div className="opacity-0 group-hover:opacity-100 transition-all duration-1000 ease-in-out basis-1/3 shrink-0 flex flex-col items-center justify-center group-hover:bg-transparent rounded-b-xl py-4 absolute w-full bottom-4 md:bottom-10">
              <p
                className={cx(
                  "hidden group-hover:block uppercase text-sm md:text-lg text-gray-600 font-bold font-mono",
                  styles.typewriter
                )}
              >
                {name}
              </p>

              <div className="hidden group-hover:block w-2/3 bg-white rounded-full mt-2">
                <m.div
                  className="h-3 rounded-full bg-green-500"
                  initial={{ width: 0 }}
                  whileInView={{
                    width: `${level * 20}%`,
                    scale: level === 5 ? [1, 1.05] : 1,
                  }}
                  transition={{
                    duration: 0.8,
                    scale: {
                      ease: "linear",
                      repeat: Infinity,
                      repeatType: "reverse",
                      duration: 1,
                    },
                  }}
                />
              </div>
            </div>
          </div>
        ))}
    </Carousel>
  );
};

export default TechSection;
