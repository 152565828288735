import { useState } from "react";
import { useNavigate } from "react-router-dom";

import AnimatedIconButton from "./AnimatedIconButton";
import AboutIcon from "../../../svg/about";

interface ProjectButtonProps {
  show?: boolean;
}

const ProjectButton: React.FC<ProjectButtonProps> = ({ show }) => {
  const navigate = useNavigate();
  const [isHovered, setIsHovered] = useState<boolean>(false);

  const innerVariants = {
    default: { scale: 1 },
    hovered: { scale: 0 },
  };

  const outerVariants = {
    shown: { scale: 1, transition: { duration: 0.3 } },
    hidden: { scale: 0, transition: { duration: 0.3 } },
  };

  const innerState = isHovered ? "hovered" : "default";
  const outerState = show ? "shown" : "hidden";

  return (
    <AnimatedIconButton
      outerInitial="hidden"
      innerInitial="default"
      outerState={outerState}
      innerState={innerState}
      outerVariants={outerVariants}
      innerVariants={innerVariants}
      onEnter={() => setIsHovered(true)}
      onLeave={() => setIsHovered(false)}
      onClick={() => navigate("/about")}
    >
      <span className="block absolute top-[10px] left-[10px] w-5">
        <AboutIcon fill={innerState === "hovered" ? "#000000" : "#ffffff"} />
      </span>
    </AnimatedIconButton>
  );
};
export default ProjectButton;
