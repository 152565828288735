import { PropsWithChildren } from "react";
import { m } from "framer-motion";

import Header from "../components/header";

type LayoutProps = PropsWithChildren;

const BasicLayout: React.FC<LayoutProps> = ({ children }) => {
  return (
    <div className="w-full flex flex-col bg-gray-100">
      <Header />
      <m.main
        initial={{ y: "-100vh" }}
        animate={{ y: 0 }}
        exit={{
          opacity: 0,
          y: "100vh",
          transition: { opacity: { duration: 0.3 }, y: { duration: 0.5 } },
        }}
        className="grow text-center bg-header-pattern bg-repeat overflow-x-hidden relative z-10 pt-12"
        style={{ height: "100vh" }}
      >
        {children}
      </m.main>
    </div>
  );
};

export default BasicLayout;
