const About = ({ fill }: { fill: string }) => {
  return (
    <svg
      version="1.0"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 1504.000000 1396.000000"
      preserveAspectRatio="xMidYMid meet"
    >
      <g
        transform="translate(0.000000,1396.000000) scale(0.100000,-0.100000)"
        fill="#000000"
        stroke="none"
      >
        <path
          fill={fill}
          d="M6980 12435 c-1556 -128 -2966 -888 -3916 -2110 -438 -563 -761
-1212 -958 -1925 -232 -839 -252 -1788 -57 -2647 299 -1315 1065 -2465 2164
-3252 822 -588 1781 -933 2806 -1011 290 -22 728 -11 1021 26 584 73 1091 214
1612 448 269 120 429 206 678 364 454 288 856 634 1217 1045 518 591 921 1342
1138 2122 182 654 241 1354 170 2042 -75 732 -318 1490 -678 2118 -373 651
-843 1194 -1422 1646 -630 490 -1373 838 -2175 1018 -506 114 -1100 157 -1600
116z m517 -2056 c103 -11 229 -46 303 -84 235 -120 409 -341 476 -605 28 -110
26 -306 -4 -420 -80 -306 -313 -550 -612 -642 -99 -30 -114 -32 -260 -33 -152
0 -157 1 -270 37 -238 77 -426 238 -534 461 -69 140 -90 234 -90 397 0 138 10
196 55 320 109 297 408 532 726 568 110 13 104 13 210 1z m118 -2319 c130 -41
222 -96 315 -190 99 -99 148 -178 193 -310 l32 -95 0 -1645 c0 -1571 -1 -1648
-18 -1705 -66 -214 -179 -366 -346 -465 -241 -143 -541 -143 -782 0 -167 99
-280 251 -346 465 -17 57 -18 134 -18 1695 0 1305 3 1645 13 1685 80 307 300
521 603 585 77 16 275 5 354 -20z"
        />
      </g>
    </svg>
  );
};

export default About;
