import { useState, useCallback, useEffect } from "react";
import { m } from "framer-motion";
import cx from "classnames";

import useScreen from "../../hooks/useScreen";
import CircleGrid from "../common/circleGrid";
import ProgressiveImage from "../common/ProgressiveImage";
import style from "../common/circleGrid.module.css";

const PersonalPics = () => {
  const screen = useScreen();
  const [isMounting, setIsMounting] = useState<boolean>(true);
  const [typeFirstHobby, setTypeFirstHobby] = useState<boolean>(false);
  const [hobbies, setHobbies] = useState<string[]>([]);

  const setNextHobby = useCallback((description: string) => {
    setHobbies((prev) => {
      if (prev.includes(description)) return prev;
      return [...prev, description];
    });
  }, []);

  useEffect(() => {
    setTimeout(() => setIsMounting(false), 3500);
  }, []);

  useEffect(() => {
    if (hobbies.length === 1) {
      setTimeout(() => setTypeFirstHobby(true), screen.sm ? 400 : 600);
    }
  }, [hobbies, screen]);

  return (
    <>
      <div className="text-center relative mt-12 px-8 h-64 min-h-64 w-full mx-auto leading-loose">
        <m.p
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: 0.3 }}
          className={cx(style.typewriter, style.typeFirst)}
        >
          Hi, I'm Jonathan.
        </m.p>
        <m.p
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: 1.5 }}
          className={cx(style.typewriter, style.typeSecond)}
        >
          I'm a frontend engineer {screen.sm && "living in Boulder, CO."}
        </m.p>
        <m.p
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: 2.4 }}
          className={cx("sm:hidden", style.typewriter, style.typeThird)}
        >
          living in Boulder, CO.
        </m.p>

        {hobbies.length > 0 && (
          <p className={cx(style.typewriter)}>In my free time</p>
        )}
        {hobbies.length > 0 && typeFirstHobby && (
          <p className={cx(style.typewriter)}>I like to {hobbies[0]}</p>
        )}
        {hobbies.length > 1 && (
          <p className={cx(style.typewriter)}>{`${hobbies[1]}`}</p>
        )}
        {hobbies.length > 2 && (
          <p className={cx(style.typewriter)}>{`and ${hobbies[2]}.`}</p>
        )}
      </div>
      <m.div
        initial={{ scale: 0, opacity: 0 }}
        animate={{ scale: 1, opacity: 1 }}
        transition={{
          duration: 0.6,
          ease: [0, 0.71, 0.2, 1.01],
          scale: {
            type: "spring",
            damping: 10,
            stiffness: 100,
            restDelta: 0.001,
          },
          delay: 0.5,
        }}
        className="mx-8 mt-8 relative"
      >
        <CircleGrid>
          <ProgressiveImage
            className={cx(isMounting && style.open)}
            placeholder="https://live.staticflickr.com/65535/54047278043_fca00d43b7_m.jpg"
            src="https://live.staticflickr.com/65535/54047278043_fca00d43b7_z.jpg"
            alt="charlie"
            loading="lazy"
          />
          <ProgressiveImage
            onMouseEnter={() =>
              setTimeout(() => setNextHobby("explore the backcountry"), 200)
            }
            className={cx(isMounting && style.closed)}
            placeholder="https://live.staticflickr.com/65535/54047470225_2973422061_m.jpg"
            src="https://live.staticflickr.com/65535/54047470225_2973422061_z.jpg"
            alt="backcountry"
            loading="lazy"
          />
          <ProgressiveImage
            onMouseEnter={() =>
              setTimeout(() => setNextHobby("spend time with family"), 200)
            }
            className={cx(isMounting && style.closed)}
            placeholder="https://live.staticflickr.com/65535/54047470050_3732bed1f1_t.jpg"
            src="https://live.staticflickr.com/65535/54047470050_3732bed1f1_z.jpg"
            alt="family"
            loading="lazy"
          />
          <ProgressiveImage
            onMouseEnter={() =>
              setTimeout(() => setNextHobby("climb big rocks"), 200)
            }
            className={cx(isMounting && style.closed)}
            placeholder="https://live.staticflickr.com/65535/54050085531_95f30db987_m.jpg"
            src="https://live.staticflickr.com/65535/54050085531_95f30db987_z.jpg"
            alt="climbing"
            loading="lazy"
          />
        </CircleGrid>
      </m.div>
    </>
  );
};

export default PersonalPics;
