import { useState, ChangeEvent, FormEvent, FormEventHandler } from "react";

export type HTMLElementEvent<T extends HTMLElement, G> = G & {
  target: T;
};

const useForm = (initialState = {}, callback: () => void) => {
  const [values, setValues] = useState(initialState);

  const onChange = (event: HTMLElementEvent<HTMLInputElement, ChangeEvent>) => {
    const { target } = event;
    setValues({ ...values, [target.name]: target.value });
  };

  const reset = () => {
    setValues(initialState);
  };

  const onSubmit: FormEventHandler<HTMLFormElement> = (event: FormEvent) => {
    event.preventDefault();
    callback();
  };

  return {
    onChange,
    onSubmit,
    values,
    reset,
  };
};

export default useForm;
