import { useEffect, useState, useCallback } from "react";

const useScrollDirection = () => {
  const [y, setY] = useState<number>(0);
  const [scrollDirection, setScrollDirection] = useState<1 | -1 | 0>(0);

  const handleScroll = useCallback(() => {
    if (y > window.scrollY) {
      setScrollDirection(1);
    } else if (y < window.scrollY) {
      setScrollDirection(-1);
    }
    setY(window.scrollY);
  }, [y]);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [handleScroll]);

  return scrollDirection;
};
export default useScrollDirection;
