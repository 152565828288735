import { useState, useRef, useEffect, useMemo, useCallback } from "react";
import { m, AnimatePresence } from "framer-motion";
import cx from "classnames";

import useScreen from "../../hooks/useScreen";
import useOnScreen from "../../hooks/useOnScreen";
import ProgressiveImage from "../common/ProgressiveImage";
import style from "./recommendations.module.css";

const testimonials = [
  {
    id: 0,
    avatar: "https://live.staticflickr.com/65535/54047470040_8e250998e7_t.jpg",
    avatarPlaceholder:
      "https://live.staticflickr.com/65535/54047470040_8e250998e7_m.jpg",
    name: "Darcy Gray",
    role: "Project Manager, NASA DEVELOP, GSFC",
    content:
      '"I managed Jon Donesky during his time at NASA DEVELOP, where he was a team member on an applied science project developing a Machine Learning flood risk model. Jon\'s coding skills were an enormous asset to the team, he wrote the majority of the code using Keras supporting Tensorflow. He was hardworking and dedicated, and had a stand-out ability to work with the team closely and navigate the relationships with advisors and partners. I highly recommend Jon for any position requiring intense coding, and I believe he can work well with a team or independently. He will wholly dedicate himself to a task and hold himself to a high standard of quality work. You will not be disappointed if you hire Jon!"',
  },
  {
    id: 1,
    avatar: "https://live.staticflickr.com/65535/54047277938_1c99d866e0_t.jpg",
    avatarPlaceholder:
      "https://live.staticflickr.com/65535/54047277938_1c99d866e0_m.jpg",
    name: "Dr. Sujay V. Kumar",
    role: "Research Scientist, NASA Goddard Space Flight Center",
    content:
      '"I was a project advisor to Jonathan’s team during their NASA DEVELOP term in Spring 2020. Jonathan’s team was working on developing a Machine Learning flood risk model for the Office of Emergency Management in Ellicott City, MD.\b\b  Jonathan did a great job during the DEVELOP term through the development of the machine learning model using open source tools. During a short amount of time, Jon was not only able to pick up the domain knowledge about the flood prediction problem, but also apply sophisticated modeling tools to this challenging problem. Jon is diligent, hardworking, thoroughly professional, and has excellent communication skills."',
  },
  {
    id: 2,
    avatar: "https://live.staticflickr.com/65535/54047470025_c0c1dd06dc_m.jpg",
    avatarPlaceholder:
      "https://live.staticflickr.com/65535/54047470025_c0c1dd06dc_t.jpg",
    name: "Perry Oddo",
    role: "Research Scientist, NASA Goddard Space Flight Center",
    content:
      '"I was a project advisor to Jonathan\'s team during their NASA DEVELOP term in Spring 2020. DEVELOP is a highly collaborative environment where diverse teams work to produce applied science solutions for end-users in need of NASA data. As the only team member with programming experience, Jonathan was a huge contributor to the machine learning model for flood prediction. He demonstrated an ability to learn and apply new skillsets in a short amount of time and his professionalism and eagerness to contribute in multiple facets of the project helped to make his term a success. I can confidently say he would be an asset to any development team."',
  },
];

const Testimonials = () => {
  const screen = useScreen();
  const [alreadySeen, setAlreadySeen] = useState<boolean>(false);
  const [index, setIndex] = useState<number>(0);

  const textContainer = useRef<HTMLDivElement>(null);
  const isOnScreen = useOnScreen(textContainer);

  const parallax = useCallback((e: MouseEvent) => {
    const elem = document.querySelector("#background") as HTMLElement;
    let _w = window.innerWidth / 2;
    let _h = window.innerHeight / 2;
    let _mouseX = e.clientX;
    let _mouseY = e.clientY;
    let _depth1 = `${50 - (_mouseX - _w) * 0.001}% ${
      50 - (_mouseY - _h) * 0.001
    }%`;
    let _depth2 = `${50 - (_mouseX - _w) * 0.001}% ${
      50 - (_mouseY - _h) * 0.001
    }%`;
    let _depth3 = `${50 - (_mouseX - _w) * 0.001}% ${
      50 - (_mouseY - _h) * 0.001
    }%`;
    let x = `${_depth3}, ${_depth2}, ${_depth1}`;

    elem.style.backgroundPosition = x;
  }, []);

  const current = useMemo(() => testimonials[index], [index]);

  useEffect(() => {
    const id = setInterval(() => {
      setIndex((state) => {
        if (state >= testimonials.length - 1) return 0;
        return state + 1;
      });
    }, 10000);

    return () => clearInterval(id);
  }, []);

  useEffect(() => {
    if (isOnScreen && !alreadySeen) {
      const tiltBox = document.querySelector("#tiltBox") as HTMLElement;
      const background = document.querySelector("#background") as HTMLElement;
      tiltBox.classList.add(style.tiltBoxBorderHovered);

      let indexPosition = 0;
      const positions = [
        {
          transform: "rotateX(1deg) rotateY(-1.5deg)",
          backgroundPosition: "translateX(0.2%)",
        },
        {
          transform: "rotateX(2deg) rotateY(2deg)",
          backgroundPosition: "translateX(-0.2%)",
        },
        {
          transform: "",
          backgroundPosition: "",
        },
      ];

      const intervalId = setInterval(() => {
        tiltBox.style.perspective = "1000px";
        tiltBox.style.transformStyle = "preserve-3d";
        tiltBox.style.transform = positions[indexPosition]?.transform;
        background.style.transform =
          positions[indexPosition]?.backgroundPosition;
        if (indexPosition === positions.length - 1) {
          tiltBox.classList.remove(style.tiltBoxBorderHovered);
        }
        indexPosition++;
      }, 600);
      setTimeout(() => setAlreadySeen(true), 2000);
      return () => clearInterval(intervalId);
    }
  }, [isOnScreen, alreadySeen]);

  if (!current) return <></>;
  const { id, name, avatar, avatarPlaceholder, role, content } = current;

  return (
    <div
      id="background"
      className={cx(
        "h-[100vh] w-full transition ease-in flex items-center justify-center pb-[200px] pt-[100px] sm:pb-52 sm:pt-52",
        style.background
      )}
    >
      <div
        className={cx("flex items-center justify-center", style.tiltBoxWrap)}
        onMouseEnter={() => document.addEventListener("mousemove", parallax)}
        onMouseLeave={() => document.removeEventListener("mousemove", parallax)}
      >
        <span className={cx(style.tOver)}></span>
        <span className={cx(style.tOver)}></span>
        <span className={cx(style.tOver)}></span>
        <span className={cx(style.tOver)}></span>
        <span className={cx(style.tOver)}></span>
        <span className={cx(style.tOver)}></span>
        <span className={cx(style.tOver)}></span>
        <span className={cx(style.tOver)}></span>
        <span className={cx(style.tOver)}></span>
        <div
          id="tiltBox"
          className={cx(
            "px-6 sm:px-24 my-32 rounded-3xl text-sm sm:text-base text-green-800 font-mono flex flex-col items-start justify-center",
            style.tiltBox,
            screen.md && style.tiltBoxBorder
          )}
        >
          <div
            className={cx(
              "flex flex-col sm:flex-row mx-auto items-center",
              style.content
            )}
          >
            <div className="shrink-0 shadow-md shadow-green-900 rounded-full">
              <AnimatePresence mode="wait">
                <m.div
                  key={`avatar:${id}`}
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0 }}
                  transition={{ damping: 100 }}
                  className="relative"
                >
                  <ProgressiveImage
                    src={avatar}
                    placeholder={avatarPlaceholder}
                    alt="avatar"
                    className="relative h-32 w-32 rounded-full mx-auto"
                  />
                </m.div>
              </AnimatePresence>
            </div>
            <div className="mt-6 sm:mt-0 sm:ml-8">
              <AnimatePresence mode="wait">
                <m.div
                  key={`namerole:${id}`}
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0 }}
                  transition={{ damping: 100 }}
                  className="pb-1 md:pb-0"
                >
                  <p className="text-center sm:text-left text-2xl mb-2 font-medium">
                    {name}
                  </p>
                  <p className="text-center sm:text-left">{role}</p>
                </m.div>
              </AnimatePresence>
            </div>
          </div>
          <div ref={textContainer} className={cx("mt-10", style.content)}>
            <AnimatePresence mode="wait">
              <m.p
                key={`content:${id}`}
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                transition={{ damping: 100 }}
                className="text-justify leading-loose"
              >
                {content}
              </m.p>
            </AnimatePresence>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Testimonials;
