import { useState, useEffect, useMemo, RefObject } from "react";

const useOnScreen = (ref: RefObject<HTMLElement>) => {
  const [isOnScreen, setIsOnScreen] = useState<boolean>(false);
  const observer = useMemo(
    () =>
      new IntersectionObserver(([entry]) =>
        setIsOnScreen(entry.isIntersecting)
      ),
    []
  );

  useEffect(() => {
    if (!ref.current) return;
    observer.observe(ref.current);
    return () => observer.disconnect();
  }, [observer, ref]);

  return isOnScreen;
};
export default useOnScreen;
