import { useState, useEffect, useCallback } from "react";
import { AnimatePresence, m } from "framer-motion";
import cx from "classnames";

import BasicLayout from "../layouts/basic";
import ProjectCard, { Project } from "../components/projects/ProjectCard";

const Projects = () => {
  const [filter, setFilter] = useState<number>(0);
  const [projects, setProjects] = useState<Project[]>([]);
  const [filtered, setFiltered] = useState<Project[]>([]);

  const fetchPopular = async () => {
    const data = await fetch(
      "https://api.themoviedb.org/3/movie/popular?api_key=95f2e1b5b470e95c8c2407b442d02dde&language=en-US&page=1"
    );

    const movies = await data.json();
    setProjects(movies.results);
    setFiltered(movies.results);
  };

  const filterProjects = useCallback(
    (currentFilter: 0 | 28 | 35) => {
      switch (currentFilter) {
        case 0:
          setFiltered(projects);
          break;
        case 35:
          setFiltered(projects.filter((x) => x.genre_ids.includes(35)));
          break;
        case 28:
          setFiltered(projects.filter((x) => x.genre_ids.includes(28)));
          break;
        default:
          setFiltered(projects);
      }
    },
    [projects]
  );

  useEffect(() => {
    fetchPopular();
  }, []);

  return (
    <BasicLayout>
      <div className="px-8 py-16">
        <div className="grid grid-cols-3 gap-x-4 mb-6 w-96">
          <button
            className={cx(
              "rounded-full px-2 py-1 font-medium bg-green-500 text-white",
              { "bg-green-300 text-gray-700": filter === 0 }
            )}
            onClick={() => {
              setFilter(0);
              filterProjects(0);
            }}
          >
            All
          </button>
          <button
            className={cx(
              "rounded-full px-2 py-1 font-medium bg-green-500 text-white",
              { "bg-green-300 text-gray-700": filter === 35 }
            )}
            onClick={() => {
              setFilter(35);
              filterProjects(35);
            }}
          >
            Comedy
          </button>
          <button
            className={cx(
              "rounded-full px-2 py-1 font-medium bg-green-500 text-white",
              { "bg-green-300 text-gray-700": filter === 28 }
            )}
            onClick={() => {
              setFilter(28);
              filterProjects(28);
            }}
          >
            Action
          </button>
        </div>
        <m.div
          layout
          layoutDependency={filtered}
          style={{
            display: "grid",
            gridTemplateColumns: "repeat(auto-fit, minmax(250px, 1fr))",
          }}
          className="gap-x-6 gap-y-5 justify-content-center align-items-center"
        >
          <AnimatePresence>
            {filtered.map((proj) => (
              <ProjectCard key={proj.id} {...proj} />
            ))}
          </AnimatePresence>
        </m.div>
      </div>
    </BasicLayout>
  );
};

export default Projects;
