import { LazyMotion } from "framer-motion";
import { BrowserRouter } from "react-router-dom";

import ScrollToTop from "./components/common/ScrollToTop";
import AnimatedRoutes from "./routes";

const loadAnimations = () =>
  import("./lib/animations/domAnimation").then((res) => res.default);

function App(): JSX.Element {
  return (
    <LazyMotion strict features={loadAnimations}>
      <BrowserRouter>
        <ScrollToTop />
        <AnimatedRoutes />
      </BrowserRouter>
    </LazyMotion>
  );
}

export default App;
