import BasicLayout from "../layouts/basic";
import PersonalPics from "../components/about/PersonalPics";

const About: React.FC = () => {
  return (
    <BasicLayout>
      <title>About</title>
      <div className="sm:container mx-auto py-16">
        <PersonalPics />
      </div>
    </BasicLayout>
  );
};

export default About;
