import BasicLayout from "../layouts/basic";
import Hero from "../components/home/hero";
import Stats from "../components/home/stats";
import Skills from "../components/home/technologies";
import Recommendations from "../components/home/recommendations";

const Home: React.FC = () => {
  return (
    <BasicLayout>
      <title>Welcome!</title>
      <Hero />
      <div className="">
        <Stats />
      </div>
      <div className="my-28 md:my-44">
        <Skills />
      </div>
      <Recommendations />
    </BasicLayout>
  );
};

export default Home;
