import BasicLayout from "../layouts/basic";

const Home: React.FC = () => {
  return (
    <BasicLayout>
      <title>Contact</title>
      <div className="sm:container mx-auto h-full w-full flex flex-col items-center justify-center sm:w-96 mx-auto leading-loose pb-32">
        <p>
          linkedin:{" "}
          <a
            className="font-medium underline"
            href="https://www.linkedin.com/in/jdonesky/"
            target="_blank"
            rel="noreferrer"
          >
            jdonesky
          </a>
        </p>
        <p>
          email: <span className="font-medium">jdonesky@gmail.com</span>
        </p>
        <p>
          phone: <span className="font-medium">(301) 356-8720</span>
        </p>
      </div>
    </BasicLayout>
  );
};

export default Home;
