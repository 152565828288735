import useScreen from "./useScreen";

const useUpper = () => {
  const { xxs, xs, sm, md, lg, xl, xxl, xxxl, xxxxl } = useScreen();

  if (xxxxl) return "xxxxl";
  if (xxxl) return "xxxl";
  if (xxl) return "xxl";
  if (xl) return "xl";
  if (lg) return "lg";
  if (md) return "md";
  if (sm) return "sm";
  if (xs) return "xs";
  if (xxs) return "xxs";
  return "xxs";
};

export default useUpper;
