import { useMemo } from "react";

import useIsClient from "./useIsClient";
import useWindowSize from "./useWindowSize";

interface Screens {
  xxs: string;
  xs: string;
  sm: string;
  md: string;
  lg: string;
  xl: string;
  xxl: string;
  xxxl: string;
  xxxxl: string;
  width: number;
}

const screens = {
  xxs: "360px",
  xs: "414px",
  sm: "640px",
  md: "768px",
  lg: "924px",
  xl: "1024px",
  xxl: "1215px",
  xxxl: "1366px",
  xxxxl: "1536px",
};

const useScreen = ({ ssrWidth = 320 } = {}) => {
  const { width: w } = useWindowSize();
  const innerWidth = w as number;
  const isClient = useIsClient();

  return useMemo(
    () =>
      Object.entries(screens).reduce(
        (obj, [screen, maxWidth]) => ({
          ...obj,
          [screen]:
            (isClient ? innerWidth : ssrWidth) >=
            Number(maxWidth.replace("px", "")),
        }),
        {
          width: innerWidth,
        }
      ),
    [isClient, ssrWidth, innerWidth]
  );
};

export default useScreen as () => Screens;
