import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { m, AnimatePresence } from "framer-motion";
import cx from "classnames";

import CalendarButton from "./common/header/ContactButton";
import AboutButton from "./common/header/AboutButton";
import style from "./Header.module.css";

const Header: React.FC = () => {
  const [navIsOpen, setNavIsOpen] = useState<boolean>(false);
  const navState = navIsOpen ? "open" : "closed";

  const container = {
    closed: { height: "56px", transition: { delay: 0.6, duration: 0.3 } },
    open: {
      height: "100vh",
    },
  };

  const item = {
    closed: { y: "-100vh", opacity: 0 },
    open: { y: "40vh", opacity: 1 },
  };

  useEffect(() => {
    if (navIsOpen) {
      document.body.style.overflow = "hidden";
    }
    return () => {
      document.body.style.overflow = "unset";
    };
  }, [navIsOpen]);

  return (
    <AnimatePresence mode="wait">
      <m.nav
        id="header"
        animate={navState}
        variants={container}
        exit="closed"
        transition={{ height: { duration: 0.5 } }}
        className={cx(
          "top-0 z-50 flex justify-end items-start w-screen px-4 pt-2.5 bg-header-pattern backdrop-blur-3xl justify-between",
          navIsOpen && style.open,
          style.header
        )}
      >
        <div className="flex items-center gap-x-2">
          {/* <ProjectsButton show /> */}
          <AboutButton show />
          <CalendarButton show />
        </div>
        <div
          className={cx(
            "rounded-full w-8 h-8 flex justify-center items-center shrink-0",
            style.arrowContainer
          )}
        >
          <m.button
            type="button"
            className={cx(style.arrow)}
            onClick={() => setNavIsOpen((prev) => !prev)}
          >
            <span></span>
            <span></span>
          </m.button>
        </div>

        <div className="absolute" style={{ left: "20%" }}>
          <div className="relative rounded-3xl h-0" style={{ left: "-50%" }}>
            <AnimatePresence>
              <m.p
                key="home"
                initial="closed"
                exit={{ y: "-100vh", opacity: 0 }}
                animate={navState}
                variants={item}
                transition={{ type: "tween", delay: 0.1, duration: 0.5 }}
                className="font-bold text-2xl text-center mb-5 pb-1 border-b-2 border-green-500 shadow-xl font-body"
              >
                <Link to="/">Home</Link>
              </m.p>
              <m.p
                key="about"
                initial="closed"
                exit={{
                  y: "-100vh",
                  opacity: 0,
                  transition: { y: { delay: 0.5 } },
                }}
                animate={navState}
                variants={item}
                transition={{ type: "tween", delay: 0.3, duration: 0.5 }}
                className="font-bold text-2xl text-center mb-5 pb-1 border-b-2 border-green-500 shadow-xl font-body"
              >
                <Link to="/about">About</Link>
              </m.p>
              {/* <m.p
                key="projects"
                initial="closed"
                exit="closed"
                animate={navState}
                variants={item}
                transition={{ type: "tween", delay: 0.5, duration: 0.5 }}
                className="font-bold text-2xl text-center mb-5 pb-1 border-b-2 border-green-500 shadow-xl"
              >
                <Link to="/projects">Projects</Link>
              </m.p> */}
            </AnimatePresence>
          </div>
        </div>
      </m.nav>
    </AnimatePresence>
  );
};

export default Header;
