import { useState, useEffect } from "react";

interface ProgressiveImageProps {
  placeholder: string;
  src: string;
  alt: string;
  loading?: "eager" | "lazy" | undefined;
  onMouseEnter?: () => void;
  className?: string;
}

const ProgressiveImage: React.FC<ProgressiveImageProps> = ({
  placeholder,
  src,
  loading = "lazy",
  onMouseEnter,
  ...props
}) => {
  const [imgSrc, setImgSrc] = useState<string>(placeholder || src);

  useEffect(() => {
    const img = new Image();
    img.src = src;
    img.onload = () => {
      setImgSrc(src);
    };
  }, [src]);

  return (
    <img
      src={imgSrc}
      onMouseEnter={onMouseEnter}
      {...props}
      alt={props.alt || ""}
    />
  );
};

export default ProgressiveImage;
