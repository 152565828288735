import { m } from "framer-motion";
import cx from "classnames";

import style from "./stats.module.css";

const stats = [
  { number: "3", caption: "years of experience" },
  { number: "500+", caption: "stories completed" },
  { number: "1,500+", caption: "contributions last year" },
  { number: "1:1,000,000", caption: "odds you'll find a harder worker" },
];

const Stats = () => {
  return (
    <div>
      {stats.map(({ number, caption }) => (
        <m.div
          key={number}
          initial={{ opacity: 0, y: 45 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.6 }}
          className="mb-6"
        >
          <p
            className={cx(
              "text-5xl sm:text-7xl font-bold font-title",
              style.shine
            )}
          >
            {number}
          </p>
          <p className="text-lg font-medium font-title text-green-600">
            {caption}
          </p>
        </m.div>
      ))}
    </div>
  );
};

export default Stats;
