import { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";

import AnimatedIconButton from "./AnimatedIconButton";
import ConnectIcon from "../../../svg/connect";

interface ContactButtonProps {
  show?: boolean;
}

const ContactButton: React.FC<ContactButtonProps> = ({ show }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [isHovered, setIsHovered] = useState<boolean>(false);

  const innerVariants = {
    default: { scale: 1 },
    hovered: { scale: 0 },
  };

  const outerVariants = {
    shown: { scale: 1, transition: { duration: 0.3 } },
    hidden: { scale: 0, transition: { duration: 0.3 } },
  };

  const innerState = isHovered ? "hovered" : "default";
  const outerState = show ? "shown" : "hidden";

  useEffect(() => {
    if (location.pathname !== "/") return;
    setTimeout(() => {
      setIsHovered(true);
    }, 4500);
    setTimeout(() => {
      setIsHovered(false);
    }, 6600);
  }, [location.pathname]);

  return (
    <AnimatedIconButton
      outerInitial="hidden"
      innerInitial="default"
      outerState={outerState}
      innerState={innerState}
      outerVariants={outerVariants}
      innerVariants={innerVariants}
      onEnter={() => setIsHovered(true)}
      onLeave={() => setIsHovered(false)}
      onClick={() => navigate('/contact')}
    >
      <span className="block absolute top-2.5 left-[10px] w-5">
        <ConnectIcon fill={innerState === "hovered" ? "#000000" : "#ffffff"} />
      </span>
    </AnimatedIconButton>
  );
};
export default ContactButton;
