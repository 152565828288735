import { m } from "framer-motion";

export interface Project {
  id: number;
  title: string;
  backdrop_path: string;
  genre_ids: number[];
}

const ProjectCard: React.FC<Project> = ({ title, backdrop_path }) => {
  return (
    <m.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      layout
    >
      <h2 className="text-left text-sm font-medium truncate mb-2">{title}</h2>
      <img
        style={{ height: "30vh" }}
        className="object-cover rounded-xl"
        src={`https://image.tmdb.org/t/p/w500${backdrop_path}`}
        alt="poster"
      />
    </m.div>
  );
};

export default ProjectCard;
