import { useRef, useState, useEffect, PropsWithChildren } from "react";
import { m } from "framer-motion";

import useOnScreen from "../../hooks/useOnScreen";
import useScrollDirection from "../../hooks/useScrollDirection";

const Carousel: React.FC<PropsWithChildren> = ({ children }) => {
  const scrollContainer = useRef<HTMLDivElement>(null);
  const isOnScreen = useOnScreen(scrollContainer);
  const scrollDirection = useScrollDirection();
  const [hasStarted, setHasStarted] = useState(false);

  useEffect(() => {
    if (!scrollContainer.current || !isOnScreen || hasStarted) return;
    setInterval(() => {
      if (!scrollContainer.current || scrollDirection > 0) return;
      scrollContainer.current.scrollLeft += 1.5;
    }, 25);
  }, [isOnScreen, scrollContainer, scrollDirection, hasStarted]);

  useEffect(() => {
    // reset when user scrolls away
    if (!scrollContainer.current || isOnScreen) return;
    scrollContainer.current.scrollLeft = 0;
  }, [isOnScreen]);

  useEffect(() => {
    if (isOnScreen) setHasStarted(true);
  }, [isOnScreen]);

  return (
    <m.div
      ref={scrollContainer}
      className="flex items-center overflow-x-auto scrollbar-hide py-12"
    >
      {children}
    </m.div>
  );
};

export default Carousel;
