import { PropsWithChildren } from "react";
import { m, AnimatePresence, Variants, VariantLabels } from "framer-motion";
import cx from "classnames";

type AnimatedIconButtonProps = {
  type?: "button" | "reset" | "submit";
  outerInitial: VariantLabels;
  innerInitial: VariantLabels;
  outerState: string;
  innerState: string;
  outerVariants: Variants;
  innerVariants: Variants;
  outerClassName?: string;
  innerClassName?: string;
  onEnter: () => void;
  onLeave: () => void;
  onClick: () => void;
} & PropsWithChildren;

const AnimatedIconButton: React.FC<AnimatedIconButtonProps> = ({
  type = "button",
  outerInitial,
  innerInitial,
  outerState,
  innerState,
  outerVariants,
  innerVariants,
  outerClassName,
  innerClassName,
  onEnter,
  onLeave,
  onClick,
  children,
}) => {
  return (
    <AnimatePresence mode="wait">
      <m.button
        type={type}
        initial={outerInitial}
        animate={outerState}
        variants={outerVariants}
        onMouseEnter={onEnter}
        onMouseLeave={onLeave}
        onClick={onClick}
        className={cx(
          "relative h-10 w-10 p-1 items-center bg-gradient-to-r from-green-500 to-teal-300 rounded-3xl text-white hover:text-black font-bold text-sm",
          outerClassName
        )}
      >
        <m.div
          initial={innerInitial}
          animate={innerState}
          variants={innerVariants}
          className={cx("bg-black rounded-2xl h-8 w-8", innerClassName)}
        />
        {children}
      </m.button>
    </AnimatePresence>
  );
};
export default AnimatedIconButton;
